import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const API_BASE_URL = "http://127.0.0.1:8000";

function LatestVideos() {
    const [videos, setVideos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${API_BASE_URL}/videos/latest/`)
            .then(response => {
                setVideos(response.data);
            })
            .catch(error => console.error('There was an error!', error));
    }, []);

    return (
        <div className="sub-block gradient-border videos">
            <div className="margin-top-standart left">
                        <h2>ПОСЛЕДНИЕ ТАНЦЕВАЛЬНЫЕ <span className="pink">КУРСЫ</span></h2>
            </div>

            <div className="margin-top-standart flex ">
                            {videos.map((video) => (
                <div key={video.id} className="video-preview" onClick={() => navigate(`/videos/${video.id}`)}>
                    <div className="  ">
                        <img className=" video-preview" src={`${API_BASE_URL}${video.cover_image}`} alt={video.title} />
                    </div>
                    <p>{video.title}</p>
                    {/* Допустим, у вас есть компонент <CoachInfo> для отображения информации о преподавателе */}
                    {/* <CoachInfo coachId={video.coach} /> */}
                </div>
            ))}
            </div>

        </div>
    );
}

export default LatestVideos;
