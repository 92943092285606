import React from 'react';

function EndPage({openModal}) {
    return (
        <div className="flex block"  id="questions">
            <div className="main-description left center">
                <h1>Остались вопросы?</h1>
                <h2> Свяжитесь с нами! </h2>
                <div className="header-actions">
                    <button className="action-button"  onClick={() => openModal('Студии')}>Узнать больше</button>
                </div>
            </div>
            <div className="end-page-logo center">
                <img className="end-page-logo" src='/images/endblock.png' alt='Лучшие студии танцев в Санкт-Петербурге | DanceXP'/>
            </div>

        </div>
    );
}

export default EndPage;
