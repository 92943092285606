import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from './components/Header/Header';
import MainPage from './components/MainPage/MainPage';
import EndPage from "./components/EndPage/EndPage";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CategoriesList from './components/CategoriesList/CategoriesList'

import CategoryDetail from './components/CategoryDetail/CategoryDetail';

import './App.css';
import { serverUrl } from "./config/config";

function App() {
    // Состояния для управления модальным окном и выбранным действием
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');

    // Функции для открытия и закрытия модального окна
    const openModal = (action) => {
        setIsModalOpen(true);
        setSelectedAction(action);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedAction('');
    };


    return (
        <Router>
            <div className="App">
                <Helmet>
                    <title>DanceXP - Танцуй, Развивайся!</title>
                    <meta name="description" content="Хочешь Научиться Танцевать? Ищешь Танцевальные Студии в Санкт-Петербурге? Хочешь Танцевать, не выходя из дома? Танцевальные школы и студии в Санкт-Петербурге (СПб) и танцевальные онлайн занятия | DanceXP" />
                </Helmet>
                {isModalOpen && (
                    <Modal closeModal={closeModal} action={selectedAction} />
                )}
                <Header />
                <div className="block">
                    {/* Определите маршруты внутри компонента Router */}
                    <Routes>
                        <Route path="/" element={<MainPage openModal={openModal} closeModal={closeModal} />} />
                        <Route path="/categories" element={<CategoriesList />} />
                        <Route path="/category/:categoryUrl" element={<CategoryDetail />} />
                        {/* Вы можете добавить другие маршруты здесь */}
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

// Компонент модального окна
const Modal = ({ closeModal, action }) => {
    // Состояния для данных формы и сообщений об ошибках
    const [formData, setFormData] = useState({ name: '', phone: '', action });
    const [error, setError] = useState('');

    // Обработчики событий для изменений в форме и отправки формы
    const handleInputChange = (e) => {
        if (error) setError('');
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        if (!formData.name || !formData.phone) {
            setError('Пожалуйста, заполните все поля.');
            return false;
        }
        const phoneRegex =  /^\+?[7-8]?[0-9]{10}$/;
        if (!phoneRegex.test(formData.phone)) {
            setError('Номер телефона должен быть в формате 7XXXXXXXXXX или 8XXXXXXXXXX.');
            return false;
        }
        closeModal();
        return true;
    };

    // Отправка данных формы на сервер
    const handleSendEmail = () => {
        if (!validateForm()) {
            return;
        }
        fetch(`${serverUrl}/send-email`, { // Замените URL сервера
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Email sent:', data);
                closeModal();
            })
            .catch((error) => {
                closeModal();
                console.error('Error:', error);
            });
    };

    // Обработчик клика по фону модального окна для закрытия
    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    return (
        <div className="modal" onClick={handleBackgroundClick}>
            <div className="modal-content flex">
                <div className="left-block">
                    <button className="close-button" onClick={handleBackgroundClick}>&times;</button>
                    <h2>Оставить заявку</h2>
                    <p>Наш специалист свяжется с вами <br></br>и ответит на любые ваши вопросы</p>
                    <div className="write_phone">
                        <span className="button_phone_text">Имя</span>
                        <input type="text" name="name" onChange={handleInputChange} />
                    </div>
                    <div className="write_phone">
                        <span className="button_phone_text">Телефон</span>

                        <input type="tel" name="phone" required=""  onChange={handleInputChange} placeholder="8-XXX-XXX-XX-XX"
                               title="Используйте числовой формат, не менее 6 цифр"/>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <button  className="action-button-block" onClick={handleSendEmail}>Отправить</button>
                </div>
                <div className="right-block">
                    <img className="modal-content-image" src='/images/dancexpregiht.png' alt='Лучшие студии танцев в
                     Санкт-Петербурге | DanceXP'/>
                </div>

            </div>
        </div>
    );
};

export default App;
