import React, { Component } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

class CategoriesList extends Component {
    state = {
        categories: [],
    };

    componentDidMount() {
        this.fetchCategories();
    }

    fetchCategories = () => {
        axios.get("http://127.0.0.1:8000/video_categories/")
            .then((response) => {
                this.setState({ categories: response.data });
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });
    };

    render() {
        const { categories } = this.state;

        return (
            <div>
                <h2>Categories</h2>
                <ul>
                    {categories.map((category) => (
                        <li key={category.id}>
                            <Link to={`/category/${category.category_url}`}>{category.name}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default CategoriesList;
