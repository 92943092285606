import React, { useState } from 'react';
import './Header.css';

function Header() {
    const [isNavVisible, setIsNavVisible] = useState(false);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    };

    return (
        <header className="App-header">
            <p className="header glitch">DANCE XP</p>
            <nav className={`App-nav ${isNavVisible ? 'is-visible' : ''}`}>
                <a className="header-button" href="#about">О нас</a>
                <a className="header-button" href="#direction">Направления</a>
                <a className="header-button" href="#questions">Вопросы</a>
            </nav>
            <div className='svg'>
                         <svg className='personal-cabinet' onClick={toggleNav} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0001 5C17.925 5 13.0002 9.92489 13.0002 16C13.0002 19.7789 14.9058 23.1128 17.8084 25.0931C11.0793 27.5581 6.2321 33.9259 6.008 41.4554C5.98336 42.2834 6.63465 42.9747 7.46271 42.9993C8.29077 43.024 8.98203 42.3727 9.00667 41.5446C9.24695 33.4715 15.8679 27 23.9999 27C32.1319 27 38.7529 33.4715 38.9931 41.5446C39.0178 42.3727 39.709 43.024 40.5371 42.9993C41.3652 42.9747 42.0165 42.2834 41.9918 41.4554C41.7677 33.926 36.9207 27.5583 30.1917 25.0932C33.0944 23.1129 34.9999 19.779 34.9999 16C34.9999 9.92489 30.0752 5 24.0001 5ZM16.0002 16C16.0002 11.5817 19.5819 8 24.0001 8C28.4183 8 31.9999 11.5817 31.9999 16C31.9999 20.4183 28.4183 24 24.0001 24C19.5819 24 16.0002 20.4183 16.0002 16Z" fill="url(#paint0_linear_212_1985)"/>
                <defs>
                    <linearGradient id="paint0_linear_212_1985" x1="6.00732" y1="24" x2="41.9925" y2="24" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#C212CD"/>
                        <stop offset="1" stop-color="#4B84F1"/>
                    </linearGradient>
                </defs>
            </svg>
            </div>


            <button className="nav-toggle" onClick={toggleNav}>
                <span className="hamburger"></span>
            </button>
        </header>
    );
}

export default Header;
