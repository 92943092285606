import React, { Component } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

// Хелпер для получения параметров маршрута в компоненте на основе класса
function withRouter(Child) {
    return (props) => {
        const params = useParams();
        return <Child {...props} params={params} />;
    }
}

class CategoryDetail extends Component {
    state = {
        categoryDetail: null,
    };

    componentDidMount() {
        this.fetchCategoryDetail();
    }

    componentDidUpdate(prevProps) {
        const { categoryUrl } = this.props.params;
        if (prevProps.params.categoryUrl !== categoryUrl) {
            this.fetchCategoryDetail();
        }
    }

    fetchCategoryDetail = () => {
        const { categoryUrl } = this.props.params;
        axios.get(`http://127.0.0.1:8000/video_categories/?category_url=${categoryUrl}`)
            .then((response) => {
                // Предполагается, что API возвращает массив, берем первый элемент
                this.setState({ categoryDetail: response.data[0] });
            })
            .catch((error) => {
                console.error("Error fetching category details:", error);
            });
    };

    render() {
        const { categoryDetail } = this.state;

        return (
            <div>
                {categoryDetail ? (
                    <div>
                        <h2>{categoryDetail.main_title}</h2>
                        <p>{categoryDetail.main_description}</p>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        );
    }
}

export default withRouter(CategoryDetail);
