import React, {useEffect, useRef} from 'react';
import LatestVideos, {API_BASE_URL} from '../LatestVideos/LatestVideos';
import VideoPlayer from "../VideoPlayer/VideoPlayer";


function MainPage({openModal}) {
    useEffect(() => {
        const videoElement = document.getElementById('mainVideo');
        videoElement.addEventListener('contextmenu', event => event.preventDefault());
    }, []);
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        const handleEnded = () => {
            // При окончании воспроизведения, перезапускаем видео
            videoElement.play();
        };

        videoElement.addEventListener('ended', handleEnded);

        return () => {
            videoElement.removeEventListener('ended', handleEnded);
        };
    }, []);
    return (
        <div className="Main-page"  id="main-page">
            <div className="block ">
                <div className="sub-block flex">
                    <div className="main-description left  ">
                        <h1>ОНЛАЙН ШКОЛА <span className="pink">ТАНЦЕВ</span></h1>
                        <p className="action-block-title">Откройте для себя мир танца с онлайн-курсами, доступными круглосуточно на нашем сервисе абсолютно <b>бесплатно!</b> </p>
                        <div className="more-action">
                            <button className="action-button"  onClick={() => openModal('Узнать больше - главная')}>Узнать больше</button>
                        </div>
                    </div>

                    <div  className="main-description left   ">
                        <video  ref={videoRef} autoPlay muted controlsList="nodownload" className="logo" id="mainVideo"
                                src='/video/preview_dancexp.mp4' />

                        {/*<img className="logo-mobile" src='/images/mobile-logo-dark-text.png' alt='Лучшие студии танцев в Санкт-Петербурге | DanceXP'/>*/}

                    </div>

                </div>
                <LatestVideos />
                <div className="sub-block gradient-border ">

                    <div className="flex">
                        <div className="">
                            <img className="left-image" src='/images/endblock.png' alt='Лучшие студии танцев онлайн | DanceXP'/>
                        </div>
                        <div className="left-description">
                            <div className="margin-top-standart left">
                                <h2>КАК НАЧАТЬ <span className="pink">ТАНЦЕВАТЬ</span></h2>
                            </div>
                            <p className="action-block-content">
                                  <b> 1. Скачайте мобильное приложение DanceXP или просматривайте видео прямо
                                на нашем сайте.</b> Наш сервис доступен круглосуточно, позволяя вам танцевать в любое удобное время, не
                                выходя из дома бесплатно. <br/>
                                <b> 2. Выберите направление.</b> Откройте для себя мир онлайн танца, выбрав одно из многочисленных
                                направлений: будь то уличные танцы, бальные танцы или современные танцы. Наши онлайн-курсы
                                созданы профессиональными хореографами, готовыми поделиться своим опытом и техникой.<br/>
                                <b> 3. Начните обучение.</b> Погрузитесь в процесс обучения с нашими видеоуроками. Каждый урок подробно
                                разбирает движения, позволяя вам учиться в комфортном темпе.
                                </p>
                            <div className="header-actions">
                                <button className="action-button"  onClick={() => openModal('Студии')}>Узнать больше</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="block about" id="about">
                <h2>НАШИ <span className="pink">ПРЕИМУЩЕСТВА</span></h2>
                <p className="action-block-content">
                    Откройте для себя гибкий и инновационный подход к обучению танцам с DanceXP! Если вы мечтаете научиться
                    танцевать, но не знаете с чего начать, DanceXP – это то, что вам нужно.<br></br>

                    Если у вас возникают вопросы: "Где найти танцевальные студии поблизости?" или "Возможно ли освоить
                    хореографию, не выходя из дома?", мы подберем для вас варианты:</p>
                <div className="action-blocks">
                    <div className="action-block">
                        <p className="action-block-title">
                            Доступность 24/7
                        </p>
                        <div className="action-block-content">
                            <p className="action-block-content">
                               Танцы онлайн бесплатно доступны в любое время и в любом месте. Это означает, что вы
                                можете практиковать и учиться, когда это удобно именно вам, без необходимости
                                адаптироваться к расписанию студии или школы.
                            </p>
                        </div>
                        <button className="action-button-block" onClick={() => openModal('Студии подобрать')}>
                            Подобрать
                        </button>
                    </div>
                    <div className="action-block">
                        <p className="action-block-title">
                            Широкий выбор направлений
                        </p>
                        <div className="action-block-content">
                            <p className="action-block-content">
                                Независимо от вашего текущего уровня и интересов, уроки танцев онлайн предлагают
                                разнообразие стилей от классики до современных танцев. Это дает уникальную возможность
                                исследовать новые направления и найти то, что действительно вас вдохновляет.
                            </p>
                        </div>
                        <button className="action-button-block" onClick={() => openModal('Видео подобрать')}>
                            Подобрать
                        </button>
                    </div>
                    <div className="action-block">
                        <p className="action-block-title">
                            Самостоятельное обучение
                        </p>
                        <div className="action-block-content">
                            <p className="action-block-content">
                                Обучение танцам онлайн позволяет вам контролировать свой прогресс, занимаясь в
                                индивидуальном темпе.   Благодаря возможности повторять видеоуроки самостоятельно, вы
                                можете уделять больше времени сложным движениям, без страха отставать от других.
                            </p>
                        </div>
                        <button className="action-button-block" onClick={() => openModal('Тренеры подобрать')}>
                            Подобрать
                        </button>
                    </div>
                </div>
            </div>
            <div className="block flex">
                <div className="block-dance-card">

                    <div className="dance-card long hip-hop" onClick={() => openModal('Hip-Hop')}>
                        <img src="/images/Hiphop.png" alt="Лучшие занятия по хип хоп / Hip Hop онлайн | DanceXP" />
                        <p className="dance-card-title">Hip-Hop</p>
                    </div>

                    <div className="dance-card middle zumba" onClick={() => openModal('Зумба')}>
                        <img src="/images/Zumba.png" alt="Лучшие занятия по zumba онлайн | DanceXP" />
                        <p className="dance-card-title">
                            Зумба
                        </p>
                    </div>

                </div>
                <div className="block-dance-card">
                    <div className="flex">
                        <div className="dance-card middle k-pop" onClick={() => openModal('K-pop')}>
                            <img src="/images/Kpop.png" alt="Лучшие занятия по кей поп K-pop танцам онлайн | DanceXP" />
                            <p className="dance-card-title">
                                K-pop
                            </p>
                        </div>
                        <div className="dance-card wide break-dance" onClick={() => openModal('Break dance')}>
                            <img src="/images/Breakdance.jpg" alt="Лучшие занятия по брейкдэнс Break Dance онлайн | DanceXP" />
                            <p className="dance-card-title">
                                Break dance
                            </p>

                        </div>
                    </div>
                    <div className="flex">
                        <div className="dance-card middle jazz-funk" onClick={() => openModal('Jazz funk')}>
                            <img src="/images/Jazzfunk.png" alt="Лучшие занятия по джаззфанк Jazzfunk онлайн | DanceXP" />
                            <p className="dance-card-title">
                                Jazz funk
                            </p>
                        </div>
                        <div className="flex wide right-position">
                            <div className="dance-card transparent" onClick={() => openModal('DANCE XP лого')}>
                                <p className="logo ">
                                    DANCE XP
                                </p>
                            </div>
                            <div className="dance-card middle non-margin-top non-margin-right belly-dance" onClick={() => openModal('Танец живота')}>
                                <img src="/images/belly_dance.png" alt="Лучшие занятия по танцу живота онлайн | DanceXP" />
                                <p className="dance-card-title">
                                    Танец живота (восточные танцы)
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="flex wrap-reverse">
                        <div className="dance-card wide non-margin-top vals" onClick={() => openModal('Вальс')}>
                            <img src="/images/Vals.png" alt="Лучшие занятия по танцам онлайн | DanceXP" />
                            <p className="dance-card-title">
                                Классический танец
                            </p>
                        </div>
                        <div className="dance-card middle non-margin-top tango" onClick={() => openModal('Танго')}>
                            <img src="/images/tango.png" alt="Лучшие занятия по танго онлайн | DanceXP" />
                            <p className="dance-card-title">
                                Танго
                            </p>
                        </div>
                    </div>
                </div>

                <div className="block-dance-card">
                    <div className="dance-card middle vogue" onClick={() => openModal('Vogue')}>
                        <img src="/images/vogue.png" alt="Лучшие занятия по Vogue онлайн | DanceXP" />
                        <p className="dance-card-title">
                            Vogue
                        </p>
                    </div>
                    <div className="dance-card long high-heels" onClick={() => openModal('High heels')}>
                        <img src="/images/highheels.png" alt="Лучшие занятия по High heels онлайн | DanceXP" />
                        <p className="dance-card-title">
                            High heels
                        </p>
                    </div>
                </div>
            </div>
                            <div className="sub-block gradient-border ">

                    <div className="flex">
                        {/*<div className="">*/}
                        {/*    <img className="left-image" src='/images/endblock.png' alt='Лучшие студии танцев в Санкт-Петербурге | DanceXP'/>*/}
                        {/*</div>*/}
                        <div className="">
                            <div className="margin-top-standart left">
                                <h2>ЛИЧНЫЙ <span className="pink">КАБИНЕТ</span></h2>
                            </div>
                            <p className="action-block-content">
                                 · Получите доступ к личному кабинету. Создайте свой уникальный профиль, который
                                      позволит отслеживать ваш прогресс, запоминать избранные курсы и адаптировать
                                      платформу под ваши индивидуальные предпочтения. <br/> Наш сервис доступен
                                круглосуточно, позволяя вам танцевать в любое удобное время, не
                                выходя из дома бесплатно. <br/>
                                 · Легкий доступ к избранным урокам. Добавляйте полюбившиеся уроки в избранное,
                                    создавайте собственные плейлисты для различных танцевальных стилей и возвращайтесь к
                                    ним в любое время. <br/> Будь то танцы для начинающихн или мастер-классы для продвинутых,
                                    ваш личный кабинет сохранит все в одном месте.
                                </p>
                            <div className="header-actions">
                                <button className="action-button"  onClick={() => openModal('Студии')}>Узнать больше</button>
                            </div>
                        </div>
                          <div className="">
                            <img className="left-image" src='/images/personal.png' alt='Лучшие студии танцев онлайн | DanceXP'/>
                        </div>
                    </div>
                </div>
            <div className="flex block"  id="questions">
                <div className="main-description left center">
                    <h1>Остались вопросы?</h1>
                    <h2> Свяжитесь с нами! </h2>
                    <div className="header-actions">
                        <button className="action-button"  onClick={() => openModal('Студии')}>Узнать больше</button>
                    </div>
                </div>
                <div className="end-page-logo center">
                    <img className="end-page-logo" src='/images/endblock.png' alt='Лучшие студии танцев онлайн | DanceXP'/>
                </div>

            </div>
        </div>

    );
}

export default MainPage;
